export default defineStore('form', () => {
  const user = useSupabaseUser()
  const supabase = useSupabaseClient<AppDatabase>()
  const { data: forms, refresh: refreshForms } = useFormRows()
  const publishedForms = computed(() =>
    forms.value.filter((form) => form.metadata?.status !== 'draft'),
  )
  const draftForms = computed(() =>
    forms.value.filter((form) => form.metadata?.status === 'draft'),
  )
  const formId = ref<string | null>(null)
  const form = computed(() => forms.value.find(({ id }) => id === formId.value))
  const usage = ref<AppUsage>({
    value: 0,
    subscription: undefined,
    submissions: [],
  })
  const apiKeys = ref<AppApiKey[]>([])
  const formMembersByFormId = ref<Record<string, AppFormMember[]>>({})
  const formMembers = computed(() =>
    form.value ? (formMembersByFormId.value[form.value.id] ?? []) : [],
  )
  const selfMember = computed(() => {
    const route = useRoute()
    const selfMember = formMembers.value.find(({ id }) => id === user.value?.id)

    if (
      !selfMember &&
      (route.params.formId || route.params.formId !== formId.value)
    ) {
      formId.value = null
      navigateTo('/')
    }

    return selfMember
  })
  const isOwner = computed(() => selfMember.value?.metadata?.role === 'owner')
  const isEditor = computed(
    () =>
      selfMember.value?.metadata?.role === 'owner' ||
      selfMember.value?.metadata?.role === 'editor',
  )
  const isPlanInactive = computed(
    () => usage.value?.subscription?.status !== 'active',
  )
  const isDraft = computed(
    () => isPlanInactive.value || form.value?.metadata?.status === 'draft',
  )
  const isHidden = computed(
    () => !isPlanInactive.value && form.value?.metadata?.status === 'hidden',
  )
  const isPrivate = computed(() => isDraft.value || isHidden.value)
  const throttledRefreshForms = useThrottleFn(refreshForms, 1000)
  const throttledRefreshUsage = useThrottleFn(refreshUsage, 1000)
  const throttledRefreshApiKeys = useThrottleFn(refreshApiKeys, 1000)
  const throttledRefreshFormMembersByFormId = useThrottleFn(
    refreshFormMembersByFormId,
    1000,
  )

  async function refreshFormMembersByFormId() {
    try {
      const ids = forms.value.map(({ id }) => id)

      if (ids.length === 0) {
        formMembersByFormId.value = {}
        return
      }

      const { data, status_code } = await $fetch('/api/form/members', {
        params: {
          form_ids: ids,
        },
        ignoreResponseError: true,
        retry: 0,
      })

      if (status_code === 200 && typeof data === 'object') {
        formMembersByFormId.value =
          (data as Record<string, AppFormMember[]>) ?? {}
      } else {
        throw new Error('Could not fetch form members.')
      }
    } catch (error) {
      console.error(error)

      formMembersByFormId.value = {}
    }
  }

  async function refreshUsage() {
    try {
      const { data, status_code } = await $fetch(
        `/api/form/${formId.value}/report`,
        {
          ignoreResponseError: true,
          retry: 0,
        },
      )

      if (status_code === 200 && typeof data === 'object') {
        usage.value = (data as AppUsage) ?? {
          value: 0,
          subscription: undefined,
          submissions: [],
        }
      }
    } catch (error) {
      console.error(error)

      usage.value = {
        value: 0,
        subscription: undefined,
        submissions: [],
      }
    }
  }

  async function refreshApiKeys() {
    try {
      if (!formId.value) {
        apiKeys.value = []
        return
      }

      const { data, error } = await supabase
        .from('api_keys')
        .select('*')
        .eq('form_id', formId.value)
        .order('created_at', { ascending: false })

      if (error) {
        throw error
      }

      apiKeys.value = (data ?? []) as AppApiKey[]
    } catch (error) {
      console.error(error)

      apiKeys.value = []
    }
  }

  function setFormById(id: string | null) {
    formId.value = id
  }

  watch(
    forms,
    async (value) => {
      if (value.length === 0) {
        return
      }

      await throttledRefreshFormMembersByFormId()
    },
    { immediate: true, deep: true },
  )

  watch(
    formId,
    async (value) => {
      if (value) {
        await throttledRefreshUsage()
        await throttledRefreshApiKeys()
      }
    },
    { immediate: true },
  )

  watch(user, async (value) => {
    if (value) {
      await throttledRefreshForms()
    } else {
      formId.value = null
      forms.value = []
    }
  })

  useRealtime(async (event) => {
    if (event.table === 'forms') {
      if (event.eventType === 'INSERT') {
        forms.value.push(event.new)
      } else if (event.eventType === 'UPDATE') {
        forms.value = forms.value.map((form) =>
          form.id === event.old.id ? event.new : form,
        )
      } else if (event.eventType === 'DELETE') {
        forms.value = forms.value.filter((form) => form.id !== event.old.id)
      }
    }

    if (event.table === 'user_forms') {
      await throttledRefreshFormMembersByFormId()
    }

    if (event.table === 'usage' && event.new?.form_id === formId.value) {
      await throttledRefreshUsage()
    }

    if (event.table === 'api_keys') {
      if (event.eventType === 'INSERT') {
        apiKeys.value.push(event.new)
      } else if (event.eventType === 'UPDATE') {
        apiKeys.value = apiKeys.value.map((form) =>
          form.id === event.old.id ? event.new : form,
        )
      } else if (event.eventType === 'DELETE') {
        apiKeys.value = apiKeys.value.filter((form) => form.id !== event.old.id)
      }
    }
  })

  return {
    forms,
    publishedForms,
    draftForms,
    refreshForms: throttledRefreshForms,
    form,
    setFormById,
    formMembersByFormId,
    refreshFormMembersByFormId: throttledRefreshFormMembersByFormId,
    formMembers,
    selfMember,
    isOwner,
    isEditor,
    usage,
    refreshUsage: throttledRefreshUsage,
    apiKeys,
    refreshApiKeys: throttledRefreshApiKeys,
    isPlanInactive,
    isDraft,
    isHidden,
    isPrivate,
  }
})
